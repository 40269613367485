import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Entry from './components/Entry';
import Login from './components/Login';
import Register from './components/Register';
import CreatePlot from './components/CreatePlot';
import HowItWorks from './components/HowItWorks';
import HelpCenter from './components/HelpCenter';
import Search from './components/Search';
import SingleCategory from './components/SingleCategory';
import EventDetails from './components/EventDetails';
import ForgotPassword from './components/ForgotPassword';
import 'animate.css';
import TicketPurchase from './components/TicketPurchase';
import { useLoadingContext } from './components/LoadingContext';
import Loader from './components/Loader';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";



function App() {
  const { loading } = useLoadingContext()
  return (
    <div className="App">
      <ToastContainer />
      {loading && <Loader />}
      <Routes>
        <Route path='/' element={<Entry />}/>
        <Route path='/login' element={<Login />}/>
        <Route path='/forgot-password' element={<ForgotPassword />}/>
        <Route path='/register' element={<Register />}/>
        <Route path='/create-plot' element={<CreatePlot />}/>
        <Route path='/how-it-works' element={<HowItWorks />}/>
        <Route path='/help-center' element={<HelpCenter />}/>
        <Route path='/search' element={<Search />}/>
        <Route path='/categories/:name' element={<SingleCategory />}/>
        <Route path='/events/:name' element={<EventDetails />}/>
        <Route path='/ticket-purchase' element={<TicketPurchase />}/>
      </Routes>
    </div>
  );
}

export default App;
