import React, {useState, useEffect} from 'react'
import Header from './Header'
import AppHead from './AppHead'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "../css/HowItWorks.css"

const HowItWorkSkeleton = () => {
  return(
    <div className='mt-4 mb-4 div'>
        <SkeletonTheme baseColor="#ddd" highlightColor="#5555">
            <Skeleton height={200} count={1} />
            <div>
                <Skeleton height={20} count={1} />
                <Skeleton height={10} count={1} />
                <Skeleton height={10} width={200} count={1} />
            </div>
        </SkeletonTheme>
    </div>
  )
}

const How = ({item}) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
      setLoaded(true);
  };
  return(
    <div className='div'>
      {!loaded && <Skeleton height={250} count={1} />}
      <img 
        src={item.image} 
        alt='img' 
        onLoad={handleImageLoad}
        loading='lazy'
        style={{ display: loaded ? 'block' : 'none' }}/>
      <div className='p-2 bg-light mb-3'>
        <figcaption className='fw-bold'>{item.title}</figcaption>
        <p className='lead' style={{fontSize:"0.9rem"}}>{item.description}</p>
      </div>  
    </div>
  )
}

const HowItWorks = () => {
  const items = Array(8).fill(null);
  const data = [
    {
      id: 1,
      image: "https://estudentshop.com/static/images/pc2.jpg",
      title: "title to this video to be placed here",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
    },
    {
      id: 2,
      image: "https://estudentshop.com/static/images/pc2.jpg",
      title: "title to this video to be placed here",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
    },
    {
      id: 3,
      image: "https://estudentshop.com/static/images/pc2.jpg",
      title: "title to this video to be placed here",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
      id: 4,
      image: "https://estudentshop.com/static/images/pc2.jpg",
      title: "title to this video to be placed here",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
      id: 5,
      image: "https://estudentshop.com/static/images/pc2.jpg",
      title: "title to this video to be placed here",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
      id: 6,
      image: "https://estudentshop.com/static/images/pc2.jpg",
      title: "title to this video to be placed here",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
      id: 7,
      image: "https://estudentshop.com/static/images/pc2.jpg",
      title: "title to this video to be placed here",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
      id: 8,
      image: "https://estudentshop.com/static/images/pc2.jpg",
      title: "title to this video to be placed here",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
  ]
  const [loading, setLoading] = useState(true)
  useEffect(()=>{
    setTimeout(()=>{
      setLoading(()=>false)
    },1000)
  },[])
  return (
    <div className='animate__animated animate__fadeIn'>
      <Header />
      <AppHead
        title="How it Works"
        description="How to use EventPlots Easily"
        imageUrl="http://localhost:3000/images/logo.png"
        url={window.location.hostname}
        twitterCardType="summary_large_image"
      />
      <div className='container-lg pt-4 pb-4'>
        <h1 className='fw-bolder fs-4'>A complete guide to EventPlots</h1>
        <p className='lead fs-6'>EventPlots is an innovative platform designed to streamline the process of planning and executing events with efficiency and precision. This comprehensive guide aims to provide users with a step-by-step walkthrough, offering detailed insights into the functionalities and features of EventPlots. Whether you are a seasoned event organizer or a newcomer to the world of event planning, this guide will empower you to harness the full potential of EventPlots, allowing you to create, manage, and elevate your events seamlessly. From understanding the fundamental concepts to mastering advanced techniques, embark on a journey with this guide to unlock the full capabilities of EventPlots and enhance your event planning experience</p>
        <div className='four'>
          {loading ? items.map((item, index) => (<HowItWorkSkeleton key={index}/>)) :
            data.map((item)=> <How key={item.id} item={item}/>)
          }
        </div>
      
      </div>
    </div>
  )
}

export default HowItWorks