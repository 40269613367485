import React, {useEffect, useState} from 'react'
import Header from './Header'
import Navigator from './Navigator'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { Card } from './Entry';
import "../css/EventDetails.css"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';

const CategorySelect = ({ticketDetails, eventDetails}) => {
    // Function to format the date to 'August 3, 2023'
    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    return(
        <div>
            <span className='text-muted'>{eventDetails && formatDate(new Date(eventDetails.start_date_time))}</span>
            <h5 className='lead fs-6 fw-bolder pt-1 pb-1'>Please select a ticket Type</h5>
            {ticketDetails && ticketDetails.map(item => 
                <div key={item.id} className='d-flex justify-content-between align-items-center border p-2 rounded mb-2'>
                    <p>{item.ticket_category.name} @ Ugx {item.ticket_price.toLocaleString()} /=</p>
                    <div className='d-flex align-items-center gap-2'>
                        <p>{item.tickets_available.toLocaleString()} Remaining</p>
                        <Link to={`/ticket-purchase?event=${item.slug}&ticket=${item.id}`} className='btn btn-sm btn-success'>Select Category</Link>
                    </div>
                </div>
            )}
        </div>
    )
}
const EventDetails = () => {
    let { name } = useParams();
    const { loading, setLoading } = useLoadingContext()
    const [show, setShow] = useState(false);
    const { getRequest } = useApiRequest()
    const [eventDetails, setEventDetails] = useState(null)
    const [ticketDetails, setTicketDetails] = useState(null)
    const [cheapestTicket, setCheapestTicket] = useState(null);
    const [highestTicket, setHighestTicket] = useState(null);


    const filterTickets = (tickets) => {
        if (!tickets || tickets.length === 0) return;

        const cheapest = tickets.reduce((prev, curr) => {
            return (prev.ticket_price < curr.ticket_price) ? prev : curr;
        });

        const highest = tickets.reduce((prev, curr) => {
            return (prev.ticket_price > curr.ticket_price) ? prev : curr;
        });

        setCheapestTicket(cheapest);
        setHighestTicket(highest);
    };

    // Function to format the date to 'August 3, 2023'
    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const fetchEventDetails = async() => {
        const details = await getRequest(`${domain}/event-details/${name}`)
        setEventDetails(details)
    }

    const fetchTicketDetails = async() => {
        const details = await getRequest(`${domain}/get-tickets?slug=${name}`)

        setTicketDetails(details)
        filterTickets(details);

    }

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);

          if (!name) {
            setLoading(false);
            return;
          }

          try {
            // Perform all fetch requests in parallel and wait for all of them to complete
            await Promise.all([fetchEventDetails(), fetchTicketDetails()]);
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            // Set loading to false when all requests are completed (or error occurs)
            setLoading(false);
          }
        };
    
        fetchData();
        window.scrollTo(0, 0);
    }, [name]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='animate__animated animate__fadeIn'>
            <Header />
            <div className='details'>
                <div className='detailsIntroduction'>
                    <img className='w-100' src={eventDetails && domain+eventDetails.banner} />
                    <div>
                        <p>{eventDetails && eventDetails.event_category.name}</p>
                        <h3>{eventDetails && eventDetails.event_plot_name}</h3>
                        <p>Event By: {eventDetails && eventDetails.organiser.organiser_name}</p>
                    </div>
                </div>
                <div className='row container detailsInner'>
                    <div className='col-lg-8'>
                        <div>
                            <h2 class="fs-6">About</h2>
                            <p>{eventDetails && eventDetails.event_description}</p>
                        </div>
                        <div>
                            <h2 class="fs-6">Age Restriction</h2>
                            <p>{eventDetails && eventDetails.age_restriction}</p>
                            <h2 class="fs-6">Organiser</h2>
                            <p>{eventDetails && eventDetails.organiser.organiser_name}</p>
                            <h2 class="fs-6">Refund Policy</h2>
                            <p>{eventDetails && eventDetails.refund_policy}</p>
                            <div class="row">
                                <div class="col-lg-6">
                                    <h2 class="fs-6"><i class="fa fa-calendar" aria-hidden="true"></i> Date and Time</h2>
                                    <p>{eventDetails && formatDate(new Date(eventDetails.start_date_time))}</p>
                                </div>
                                <div class="col-lg-6">
                                    <h2 class="fs-6"><i class="fa-solid fa-location-dot"></i> Location</h2>
                                    <p>Venue: {eventDetails && eventDetails.venue_name}</p>
                                    <p>Address: {eventDetails && eventDetails.venue_address}</p>
                                </div>
                            </div>
                            <iframe
                            width="100%"
                            height="300px"
                            frameborder="0" style={{border:0}}
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50
                                &q=${eventDetails && eventDetails.venue_address}`} allowfullscreen>
                            </iframe>
                            <h2 class="fs-5">Share this Event</h2>
                            <a href="https://www.facebook.com/"><i class="fab fa-whatsapp text-success fs-3 pe-2"></i> </a>
                            <a href="https://www.facebook.com/"><i class="fab fa-facebook fs-3 pe-2"></i> </a>
                            <a href="https://www.instagram.com/"><i class="fab fa-instagram text-danger fs-3 pe-2"></i> </a>
                            <a href="https://twitter.com/"><i class="fab fa-twitter fs-2 pe-2"></i> </a>
                            
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="sticky">
                            <h6>Tickets Available From </h6>
                            <h2 className="fs-6">{cheapestTicket && `Ugx ${cheapestTicket.ticket_price.toLocaleString()} /= ${highestTicket && ' to Ugx ' + highestTicket.ticket_price.toLocaleString()} /=`}</h2>
                            <hr />
                            <p><i className="fa fa-calendar icon" aria-hidden="true"></i> {eventDetails && formatDate(new Date(eventDetails.start_date_time))}</p>
                            <p><i className="fa fa-map-marker icon" aria-hidden="true"></i>  {eventDetails && eventDetails.venue_name} </p>
                            <Link onClick={handleShow} className='mb-4'>Proceed to Ticket Purchase</Link>
                        </div>
                        <div className='mt-4'>
                            {eventDetails && 
                            <img className='img-fluid' src={domain+eventDetails.listing_img} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-lg'>
                <h3 className='lead fw-light w-75 pt-4 pb-4 text-secondary fw-bold'>Other Events</h3>
                <div className='d-flex flex-wrap gap-2'>
                    <Card 
                        image={'https://estudentshop.com/static/images/pc2.jpg'}
                        title = {'we the best'}
                        description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It…'}
                        date = {'March 9, 2023, midnight'}
                        venue ={'makerere - uganda'}
                        organiser={'Bam Events'}
                    />
                    <Card 
                        image={'https://estudentshop.com/static/images/pc2.jpg'}
                        title = {'we the best'}
                        description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It…'}
                        date = {'March 9, 2023, midnight'}
                        venue ={'makerere - uganda'}
                        organiser={'Bam Events'}
                    />
                    <Card 
                        image={'https://estudentshop.com/static/images/pc2.jpg'}
                        title = {'we the best'}
                        description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It…'}
                        date = {'March 9, 2023, midnight'}
                        venue ={'makerere - uganda'}
                        organiser={'Bam Events'}
                    />
                </div>
            </div>
            <Modal 
                show={show} 
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title className='fw-bolder text-muted fs-5'>{eventDetails && eventDetails.event_plot_name} - {eventDetails && eventDetails.event_category.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CategorySelect ticketDetails={ticketDetails} eventDetails={eventDetails}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary d-none" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EventDetails