import React, {useState, useEffect} from 'react'
import Header from './Header'
import Navigator from './Navigator'
import "../css/Search.css"
import { Card } from './Entry'
import { useLocation } from 'react-router-dom';

const Search = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // Helper function to extract query parameter
  const getQuery = () => {
    return new URLSearchParams(location.search).get('q');
  };

  useEffect(() => {
    const fetchData = async () => {
        const query = getQuery();
        if (query) {
            setLoading(true);
            try {
                //const response = await axios.get(`/api/search?q=${query}`);
                //setResults(response.data);
                setResults([{name:"david hope"}])
            } catch (error) {
                console.error('Error fetching search results', error);
            } finally {
                setLoading(false);
            }
        }
    };

    fetchData();
  }, [location.search]);


  return (
    <div className='animate__animated animate__fadeIn'>
      <Header />
      <div className='container search pt-4 pb-4'>
        {loading ? (
                <p>Loading...</p> // Loading indicator
            ) : (
                <div>
                    <h2>Search Results For {getQuery()}</h2>
                    {results.length > 0 ? (
                                <div className='d-flex flex-wrap gap-2'>
                                <Card 
                                    image={'https://estudentshop.com/static/images/pc2.jpg'}
                                    title = {'we the best'}
                                    description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It…'}
                                    date = {'March 9, 2023, midnight'}
                                    venue ={'makerere - uganda'}
                                    organiser={'Bam Events'}
                                />
                                <Card 
                                    image={'https://estudentshop.com/static/images/pc2.jpg'}
                                    title = {'we the best'}
                                    description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It…'}
                                    date = {'March 9, 2023, midnight'}
                                    venue ={'makerere - uganda'}
                                    organiser={'Bam Events'}
                                />
                                <Card 
                                    image={'https://estudentshop.com/static/images/pc2.jpg'}
                                    title = {'we the best'}
                                    description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It…'}
                                    date = {'March 9, 2023, midnight'}
                                    venue ={'makerere - uganda'}
                                    organiser={'Bam Events'}
                                />
                              </div>
                    ) : (
                        <p>No results found.</p>
                    )}
                </div>
            )}
      </div>
      <Navigator />
    </div>
  )
}

export default Search