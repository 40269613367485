import React, { useState } from 'react';
import { useAuthContext } from './AuthContext';
import { getFromLocalStorage } from './EncryptDecrypt';

const useApiRequest = () => {
    const { authToken } = useAuthContext();
    const [loading, setLoading] = useState(false)

    const sendRequest = async (url, method, data = null) => {
        try {
            let headers = {};

            if (authToken !== null) {
                const token = getFromLocalStorage("authToken")
                if(token){
                    headers.Authorization = `Token ${token.token}`;
                }
            }

            const requestOptions = {
                method: method,
                headers: headers,
                body: data !== null ? data : null,
            };

            const apiResponse = await fetch(url, requestOptions);
            const responseData = await apiResponse.json();
            console.log(responseData)
            setLoading(false)
            return responseData

        } catch (err) {
            return err
        }
    };

    const postRequest = async (url, data) => {
        return await sendRequest(url, 'POST', data);
    };
    
    const putRequest = async (url, data) => {
        return await sendRequest(url, 'PUT', data);
    };
    
    const deleteRequest = async (url) => {
        return await sendRequest(url, 'DELETE');
    };
    
    const getRequest = async (url) => {
        return await sendRequest(url, 'GET');
    };

    return { postRequest, putRequest, deleteRequest, getRequest, loading, setLoading };
};

export default useApiRequest;
