import React, { useEffect, useState } from 'react'
import Header from './Header'
import { Link } from 'react-router-dom'
import "../css/Entry.css"
import Carousel from 'react-bootstrap/Carousel';
import useFetch from './useFetch'
import { domain } from './Domain'
import useApiRequest from './useApiRequest'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export const Categories = () => {
    const { data, loading, error, refresh, setRefresh } = useFetch(`${domain}/event-categories`);
    const { postRequest, getRequest, putRequest, deleteRequest, response, setResponse, error: errorApi } = useApiRequest();
    const array = new Array(3).fill(null)

    // useEffect to fetch data when component mounts and unmounts
    useEffect(() => {
        setRefresh(() => !refresh); // Fetch data when component mounts
        return () => {
            // Optional cleanup function if needed when component unmounts
        };
    }, []);
    return (
        <>
            <h2 className='text-secondary fw-bolder fs-6'>Categories</h2>
            <div className='categories d-flex flex-wrap gap-2'>
                {loading &&
                    <SkeletonTheme baseColor="#ddd" highlightColor="#5555">
                        {array.map((item, index) => (
                            <Skeleton className='flex-grow-1' key={index} height={30} width={100} count={2} />
                        ))}
                    </SkeletonTheme>
                }
                {data && data.map((category, index) => {
                    return (
                        <Link to={`/categories/${category.name.toLowerCase().replace(/\s+/g, '-')}`} className='flex-grow-1' key={index}>{category.name}</Link>
                    )
                })}
            </div>
        </>
    )
}

export const Filter = () => {
    return (
        <>
            <h2 className='text-secondary fw-bolder fs-6 pt-4'>Filter Events</h2>
            <ul className="list-group filterEvents">
                <li className="list-group-item text-white bg-secondary" aria-current="true">Today</li>
                <li className="list-group-item">This Week</li>
                <li className="list-group-item">Happening this weekend</li>
                <li className="list-group-item">Coming month Events</li>
                <li className="list-group-item">Online</li>
            </ul>
        </>
    )
}

export const FilterDistrict = () => {
    return (
        <>
            <h2 className='text-secondary fw-bolder fs-6 pt-4'>View by Distrct</h2>
            <select className='form-control'>
                <optgroup label='select a district'>
                    <option>All Districts</option>
                    <option>Kampala</option>
                    <option>Gulu</option>
                    <option>Mbale</option>
                </optgroup>
            </select>
        </>
    )
}

const CarouselImage = ({ img }) => {
    return (
        <>
            <img className='w-100' src={img} />
        </>
    )
}
const Slidder = () => {
    return (
        <div className='col-lg-8 slidder'>
            <Carousel>
                <Carousel.Item interval={1000}>
                    <CarouselImage img="https://estudentshop.com/static/images/pc2.jpg" />
                    <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                    <CarouselImage img="https://estudentshop.com/static/images/pc2.jpg" />
                    <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

const Feedback = () => {
    return (
        <div className="col-lg-4">
            <h1 className="fw-bold fs-5 lead fw-bold pt-2 pb-2" style={{ color: "darkcyan" }}><i className="fas fs-1 fa-comments" style={{ color: "darkcyan" }}></i> We appreciate your Feedback</h1>
            <p className="fs-6 pt-1 lead">We are commited to delivering a robust application to suit all your booking needs, please take some time and fill out the feedback form so as to enable us serve you better. <br /><span class="text-warning fw-bold">Thank you</span> </p>
            <Link className="btn btn-success" data-bs-toggle="modal" data-bs-target="#feedbackModal">Submit A Review</Link>
        </div>
    )
}

export const Card = ({ image, title, slug, description, date, venue, organiser, category }) => {
    const [showShareButtons, setShowShareButtons] = useState(false);

    const toggleShareButtons = () => {
        setShowShareButtons(!showShareButtons);
    }

    return (
        <div class="card p-0">
            <div className='cardImageDiv'>
                <img src={image} class="card-img-top" alt="..." />
            </div>
            <div class="card-body">
                <h3 class="fs-6 fw-normal"><i class="fa-solid fa-layer-group"></i>{category}</h3>
                <h3 class="fs-6 fw-bolder">{title}</h3>
                <p class="card-text"><Link to={`/events/${slug}`}>{description}</Link></p>
                <p class="text-danger mb-1"><i class="fa-solid fa-calendar-days"></i> {date} </p>
                <p class="text-muted mb-1"><i class="fa-solid fa-location-crosshairs"></i> {venue}</p>
                <p class="text-muted mb-1"><i class="fa-solid fa-people-roof"></i> Organiser: {organiser}</p>
                <hr />
                <div className='d-flex justify-content-evenly gap-2'>
                    <button className='flex-grow-1 btn btn-info text-white'><i class="fa-solid fa-heart"></i> 22 Likes</button>
                    <button className='flex-grow-1 btn btn-warning text-white'><i class="fa-solid fa-eye"></i> 10 Views</button>
                    <button onClick={toggleShareButtons} className='flex-grow-1 btn btn-secondary'><i class="fa-solid fa-share-nodes"></i> Share</button>
                </div>
                {/* Share Buttons */}
                <div
                    className={`mt-3 text-center share-buttons ${showShareButtons ? 'show' : 'hide'}`}
                    style={{ transition: 'opacity 0.5s ease', opacity: showShareButtons ? 1 : 0, /*height: showShareButtons ? 'auto' : 0*/ }}
                >
                    <Link to="#" className="btn btn-whatsapp m-1"><i className="fa-brands fa-whatsapp"></i></Link>
                    <Link to="#" className="btn btn-twitter m-1"><i className="fa-brands fa-twitter"></i></Link>
                    <Link to="#" className="btn btn-linkedin m-1"><i className="fa-brands fa-linkedin"></i></Link>
                    <Link to="#" className="btn btn-primary m-1"><i class="fa-brands fa-facebook"></i></Link>
                </div>
            </div>
        </div>
    )
}
const Entry = () => {
    const { data, loading, setLoading } = useFetch(`${domain}/event-details`)
    const truncateString = (str) => {
        return str.length > 90 ? str.substring(0, 89) + "..." : str;
    }

    // Function to format the date to 'August 3, 2023'
    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }
    return (
        <div>
            <div className='animate__animated animate__fadeIn'>
                <Header />
                <div className='d-flex gap-4 ps-4 pe-4 pt-4'>
                    <div className='w-25'>
                        <div className='w-100 side'>
                            <Categories />
                            <Filter />
                            <FilterDistrict />
                        </div>
                    </div>
                    <div className='w-75'>
                        <h3 className='lead fw-light w-75 text-secondary'>Looking for a place to hungout for the weekend or weekday, navigate the numerous events and have make your day count.</h3>
                        <div className='row'>
                            <Slidder />
                            <Feedback />
                        </div>
                        <h3 className='lead fw-light fw-bold pt-3 pb-2 text-secondary'>Current Events Happening on Our Platform</h3>
                        <div className='d-flex flex-wrap gap-2'>
                            {data && data.length > 0 && data.map(item =>
                                <Card
                                    key={item.id}
                                    image={`${domain}${item.listing_img}`}
                                    category={item.event_category.name}
                                    title={item.event_plot_name}
                                    slug={item.slug}
                                    description={truncateString(item.event_description)}
                                    date={formatDate(new Date(item.start_date_time))}
                                    venue={item.venue_name}
                                    organiser={item.organiser.organiser_name}
                                />
                            )}

                        </div>
                    </div>
                </div>
                {/* modal */}
                <div className="modal fade" id="feedbackModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 fw-bold" id="exampleModalLabel">Please submit Your Review</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form id="feedback">
                                    <p className="fs-6 lead">Thank you for taking time to make this platform the best, we appreciate your responses as they help us serve you better</p>
                                    <select name="reason" className="form-control">
                                        <optgroup label="Reason for your Feedback">
                                            <option value="reason" hidden>Reason for your feedback</option>
                                            <option value="Website Navigation Experience">Website Navigation Experience</option>
                                            <option value="Booking Experience">Booking Experience</option>
                                        </optgroup>
                                    </select>
                                    <label className="pt-2 pb-2 fs-6 text-muted">How do you rate your overall experience?</label><br />
                                    <input type="radio" name="experience" required value="bad" /> Bad <br />
                                    <input type="radio" name="experience" required value="average" /> Average <br />
                                    <input type="radio" name="experience" required value="good" /> Good <br />
                                    <input type="radio" name="experience" required value="verygood" /> Very Good <br />
                                    <input type="radio" name="experience" required value="excellent" /> Excellent <br />
                                    <label className="pt-2 pb-2 fs-6 text-muted">Comment</label><br />
                                    <textarea name="comment" required className="form-control" rows="4" placeholder="please specify your comment"></textarea>
                                    <div className="row m-auto g-2 pb-2">
                                        <div className="col-6">
                                            <input type="text" className="form-control" name="name" placeholder="Enter your full name" />
                                        </div>
                                        <div className="col-6">
                                            <input type="email" className="form-control" name="email" placeholder="Enter your Email Address" />
                                        </div>
                                        <div className="col-12">
                                            <input type="tel" className="form-control" name="phone" placeholder="Enter your phone number" />
                                        </div>
                                    </div>
                                    <button className="btn btn-success" onclick="feedback()">Send Feedback <i className="fa-solid fa-paper-plane"></i></button>
                                </form>
                            </div>
                            <div className="modal-footer d-none">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Entry