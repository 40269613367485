import React from 'react'
import Header from './Header'
import { Filter, Categories, FilterDistrict, Card } from './Entry'
import { useParams } from 'react-router-dom';

const SingleCategory = () => {
    let { name } = useParams();
    return (
        <div className='animate__animated animate__fadeIn'>
            <Header />
            <div className='d-flex gap-4 ps-4 pt-4 pe-4'>
                <div className='w-25'>
                    <div className='w-100 side'>
                        <Categories />
                        <Filter />
                        <FilterDistrict />
                    </div>
                </div>
                <div className='w-75 animate__animated animate__fadeInUp'>
                    <h3 className='lead fw-light w-75 text-secondary fw-bold' >Selected Category: {name.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}</h3>
                    <p className='lead fs-6 fw-light'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, </p>
                    <div className='d-flex flex-wrap gap-2'>
                        <Card 
                            image={'https://estudentshop.com/static/images/pc2.jpg'}
                            title = {'we the best'}
                            description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It…'}
                            date = {'March 9, 2023, midnight'}
                            venue ={'makerere - uganda'}
                            organiser={'Bam Events'}
                            category = {"movies"}
                        />
                        <Card 
                            image={'https://estudentshop.com/static/images/pc2.jpg'}
                            title = {'we the best'}
                            description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It…'}
                            date = {'March 9, 2023, midnight'}
                            venue ={'makerere - uganda'}
                            organiser={'Bam Events'}
                            category = {"movies"}
                        />
                        <Card 
                            image={'https://estudentshop.com/static/images/pc2.jpg'}
                            title = {'we the best'}
                            description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It…'}
                            date = {'March 9, 2023, midnight'}
                            venue ={'makerere - uganda'}
                            organiser={'Bam Events'}
                            category = {"movies"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleCategory